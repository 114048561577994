import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				FrostGear Rentals
			</title>
			<meta name={"description"} content={"Zirveler için Hazırlanın - Nihai Kar Maceranız Sizi Bekliyor"} />
			<meta property={"og:title"} content={"FrostGear Rentals"} />
			<meta property={"og:description"} content={"Zirveler için Hazırlanın - Nihai Kar Maceranız Sizi Bekliyor"} />
			<meta property={"og:image"} content={"https://forigmastate.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://forigmastate.com/img/ski.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://forigmastate.com/img/ski.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://forigmastate.com/img/ski.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://forigmastate.com/img/ski.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://forigmastate.com/img/ski.png"} />
			<meta name={"msapplication-TileImage"} content={"https://forigmastate.com/img/ski.png"} />
			<meta name={"msapplication-TileColor"} content={"https://forigmastate.com/img/ski.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-primary" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Bize Ulaşın
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Hizmetlerimiz hakkında daha fazla bilgi edinmek veya bir sonraki kar maceranıza hazırlanmak için:
				</Text>
				<Link
					href="mailto:blank?contact@forigmastate.com"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					contact@forigmastate.com
				</Link>
				<Link
					href="tel:+90 352 222 45 16"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+90 352 222 45 16
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					Hisarcık Erciyes, Erciyes Kayak Merkezi, Tekir Kapı No:39, 38000 Melikgazi/Kayseri, Türkiye
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});